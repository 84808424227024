.blue-line {
  border-bottom: 3px solid #003dff;
  width: 18px;
  height: 50%;
}

.dotted-line {
  border-bottom: 3px dashed #000000;
  width: 18px;
  height: 50%;
}

.red-alert-dot {
  background-color: #fff;
  border: 3px solid red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.btn-export {
  background: #003dff 0% 0% no-repeat padding-box;
  color: #fff;
  font: normal normal 600 16px/10px Montserrat;
  border: none;
  text-transform: uppercase;
  padding: 13.5px 18.5px;
}

.btn-cancel {
  padding: 13.5px 18.5px;
  color: #000;
  font: normal normal 600 16px/10px Montserrat;
  border: none;
  text-transform: uppercase;
  background: transparent;
}