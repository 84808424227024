.apexcharts-legend.apx-legend-position-right {
  display: none;
}

.apexcharts-canvas {
  margin-left: 20px;
}

.apexcharts-pie-area:hover {
  fill: lightslategray !important;
}

.emptyCircle {
  text-align: center;
  margin-left: 51px;
}

.projectName {
  font-weight: bold;
  margin-left: 60px;
  margin-right: 10px;
}

.noProject {
  text-align: center;
  padding: 19px;
}

.noButtonProject {
  text-align: center;
}

.buttonProject {
  text-align: unset;
}

.circle {
  width: 90px;
  height: 90px;
  background-color: #ffffff;
  /* Circle color */
  border-radius: 50%;
  /* border: 1px solid #003dff; */
  border: 1px solid #003dff;
  /* Ensures the element is a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px 65px;
  /* Add other styles as needed */
}

.card-container {
  position: relative;
}

.overflow-content {
  position: absolute;
  top: 10%;
  right: -20%;
  z-index: 1;
}

.fixedButton {
  position: fixed;
  bottom: 50px;
  left: 158px;
  z-index: 999;
}


.centerCircle {
  /* border-color: #ff7a59;
  background-color: antiquewhite;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center; */

  background-color: #fff;
  border: 1px solid #003dff;
  height: 80px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 80px;
  margin-top: 10px;
  margin-left: 90px;
  margin-right: 0px;
}

.centerOnlineCircle {
  background-color: #003dff;
  border: 1px solid #003dff;
  height: 12px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 12px;
  margin-top: 6px;
  margin-right: 9px;
}

.project-online {
  color: #2C42B8
}

.project-offline {
  color: #CAD0ED
}

.connectLine {
  margin-bottom: 0px;
  display: flex;
  color: var(--Sea, #2c42b8);
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.centerOfflineCircle {
  background-color: #cad0ed;
  border: 1px solid #cad0ed;
  height: 12px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 12px;
  margin-top: 6px;
  margin-right: 9px;
}

.countDiv {
  margin-left: 4px;
  margin-bottom: 2px;
}

.projectTitle {
  margin-top: 19px;
  margin-left: -6px;
  color: var(--Light, #fff);
  text-align: center;
  width: 134px;
  height: 38.959px;
  flex-shrink: 0;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.64px;
}

.projectValue {
  display: flex;
  width: 35px;
  height: 35px;
  padding: 3px 3.429px 3px 2.571px;
  justify-content: center;
  align-items: center;
}

.buttonCreateWebhook {
  display: flex;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #000066;
  padding: 8px 12px;
  font-size: 1rem;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.buttonCreateWebhook:hover {
  background-color: #d6ddf7;
}

.buttonCreateView {
  display: flex;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #000066;
  /* border: 2px solid #000066; */
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 8px;
  border: 2px;
  /* background: var(--Light, #fff); */
  /* float shadow */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}


.buttonCreateView:hover {
  background-color: #2C42B8;
}

.buttonCreateView:active {
  background-color: #6071CA;
}

.project-list {
  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);
  background: var(--Light, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.plusIcon {
  color: var(--Ocean, #006);
  font-size: 24px;
  margin-top: 3px;
}

.plusCreate {
  margin-top: 4px;
  margin-left: 12px;
  margin-bottom: 5px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
  color: var(--Ocean, #006);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
}

.listViewDataOption {
  display: inline-flex;
  padding: 7px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background-color: #fff;
  list-style-type: none;
  padding-left: 0px;
  background: var(--Light, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  list-style-type: none;
  padding-left: 0px;
  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);
}

.menud {
  margin-left: -35px;
}

.listOption {
  margin-left: 7px;
  color: var(--Ocean, #006);
  /* Button */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
}

.projectLabelName {
  margin-bottom: 0px;
  color: var(--Body, #313131);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.divSpace {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.divmodbus {
  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);
  background: var(--Light, #fff);

  /* pop shadow */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.auto-grid {
  width: 80%;
  --auto-grid-min-size: 20rem;
  display: grid;
  margin: auto;
  margin-right: 178px;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1.5rem;
}

@media screen and (min-width: 1600px) {
  .fixedButton {
    left: 236px;
  }
}