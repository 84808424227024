/* @import "./fonts/fonts.css"; */
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap";

body {
  font-family: "Montserrat";
  overflow-x: hidden;
  box-sizing: border-box;
}

.color-ocean-blue {
  color: #000066;
  font-weight: bold;
  margin-left: 68px;
}
.color-ocean-blue-font {
  font-size: 32px;
}

color-ocean-blue-margin-left {
  margin-left: 35px;
}

.text-red {
  color: #ba1313;
}

.page-content {
  margin-top: 2rem;
}

.fixed-top {
  background-color: #2c42b8;
}

.bg-dashboard {
  /* background: #ededed 0% 0% no-repeat padding-box; */
  /* padding: 0 40px 0 40px; */
  min-height: 100%;
  margin-left: 262px;
}

.clickable {
  cursor: pointer;
  text-decoration: none;
}

.switch-btn-block {
  margin-top: 2rem;
}

.switch-btn-grey {
  height: 3px;
  width: 100%;
  background: #dedede 0% 0% no-repeat padding-box;
}

.switch-btn-line {
  height: 2px;
  width: 100%;
  background: #000066;
  margin-bottom: 14px;
}

.switch-modbus-line {
  height: 0.5px;
  width: 100%;
  background: lightgray;
  margin-bottom: 7px;
}

.align-right {
  display: inline;
  float: right;
}

.bacecontainer-sm {
  width: 100%;
  max-width: 600px;
  margin-right: auto;
  padding-right: 30px;
}

.bacecontainer {
  width: 100%;
  max-width: 1200px;
  margin-right: auto;
  padding-right: 50px;
}

.bacecontainer-md {
  width: 100%;
  max-width: 1320px;
  margin-right: auto;
  padding-left: 10px;
}

.bacecontainer-lg {
  width: 100%;
  max-width: 1440px;
  margin-right: auto;
  /* padding-left: 10px; */
  /* padding: 0px 0px 0px 50px; */
}

.bacecard-graph {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  min-height: 137px;
  width: 1160px;
}

.bacecard-graph-chart {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  min-height: 137px;
  width: 1170px;
}

.bacecard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  min-height: 137px;
}
.bacecard-modbus-template {
  background: #ffffff 0% 0% no-repeat padding-box;
  min-height: 137px;
}

.card-p {
  padding: 0px 33px 0px 33px;
}

.card-pNew {
  padding: 0px 33px 0px 71px;
}

.bacecard-lg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  min-height: 137px;
  max-width: 1260px;
  width: 100%;
}

.bacecard-no-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  padding: 20px 50px;
  min-height: 40px;
  width: 600px;
}

.card-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  cursor: pointer;
  margin: auto;
  grid-column-gap: 12px;
  max-width: 100%;
}

.card-project {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
}

.card-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 5px;
  padding: 4px;
}

.search-input {
  background-image: url("../src/assets/search.png");
  background-position: 6px 7px;
  background-repeat: no-repeat;
  width: 100%;
  font-size: 16px;
  padding: 5px 10px 5px 35px;
  border: 2px solid #ddd;
  border-radius: 3px;
  margin-bottom: 12px;
  margin-top: 12px;
}

/* .bacecard-width {
  width: 450px;
} */
.bacecard-width-dashboard {
  width: 85%;
}

.material-icons.md-24 {
  font-size: 45px;
}

.listViewBorderStyle {
  border-bottom: 1px solid lightgray;
}

.tableRow,
.listViewStyle {
  border-style: none;
}

.noOfDevice {
  text-align: center;
}

.listView {
  background-color: rgb(223, 220, 220);
}

.dropdownView {
  border-radius: 6px;
  border: 1px solid gray;
}

.gatewaysList {
  width: 100%;
}

.pageStyle .MuiPagination-ul {
  justify-content: center;
}

.intervalRange {
  margin-top: 13px;
}

.divModal {
  padding: 0px 9px 10px 75px;
  border-bottom: 1px solid #aaa6;
}

.divViewModal {
  padding: 0px 10px 6px 75px;
  display: flex;
}

.divModbusModal {
  display: flex;
  padding: 0px 10px 10px 25px;
}

.divModalHeader {
  padding: 0px 7px 4px 16px;
  display: flex;
}

.alretIcon {
  margin-top: 8px;
}

.homeIconSize {
  font-size: 55px;
}

.textview {
  text-align: center;
}

.textviewIcon {
  text-align: center;
}

.divView {
  margin-top: 15px;
}

.rowBlank {
  height: 40px;
}

.bacecard-device-info {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  min-height: 137px;
  /* width: 550px; */
}

.bacenotes-width-sm {
  width: 600px;
}

.bacelist-width {
  width: 635px;
}

.bacecontainer-sm {
  width: 100%;
  max-width: 1096px;
  padding-left: 15px;
  margin-right: auto;
}

.navbar-project-name {
  font: normal normal 300 12px/18px Montserrat;
  letter-spacing: 1.2px;
  color: #e3e3e3;
  opacity: 1;
  margin-bottom: 0;
}

.navbar-project-subname {
  font: normal normal 400 30px/36px Montserrat;
  letter-spacing: 0.98px;
  color: white;
  opacity: 1;
  font-size: 32px;
}

.bacelink {
  font: normal normal normal 16px/28px Montserrat;
  letter-spacing: 0px;
  color: #000;
  padding: 0 20px 0 16px;
  opacity: 1;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.bacelink-inactive {
  font-family: Montserrat;
  letter-spacing: 0px;
  color: var(--Body, #313131);
  padding: 0 16px 0 16px;
  opacity: 1;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.bacelink:hover {
  font: normal normal 500 16px/28px Montserrat;
  color: #003dff;
  text-decoration: none;
}

.bacelink-active {
  font-family: Montserrat;
  color: var(--Sea, #2c42b8);
  padding: 0 16px 10px 16px;
  text-decoration: none;
  border-bottom: 3px solid #2c42b8;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  font-style: normal;
}

.bacelink-active:hover {
  color: #2c42b8;
  text-decoration: none;
}

.cardName {
  padding: 0px;
}

.bacelink-alert-popup-lg {
  padding: 5px 0 5px 0 !important;
  vertical-align: middle;
  text-align: left;
  font: normal normal 500 16px/16px Montserrat;
  letter-spacing: 0px;
  color: #003dff;
  cursor: pointer;
}

.bacelink-alert-popup-lg:hover {
  font: normal normal 600 16px/16px Montserrat;
  text-decoration: none;
}

.lg-card {
  font: normal normal bold 36px/36px Montserrat;
  letter-spacing: 0px;
  /* color: #1A1A1A; */
  opacity: 1;
  margin-bottom: 10px;
  white-space: nowrap;
}

.md-card {
  font: normal normal normal 24px/29px Montserrat;
  letter-spacing: 0px;
  /* color: #1A1A1A; */
  opacity: 1;
  margin-bottom: 10px;
}

/* page titles */
.page-title {
  font: normal normal normal 28px/32px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-left: 4px;
}

/* page titles */
.page-title-bold {
  font: normal normal bold 28px/32px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-left: 4px;
}

/* card headers */
.card-header {
  font: normal normal bold 22px/24px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.rowStart {
  margin-left: 10px;
}

.latestEvent {
  display: flex;
}

.tableBorder {
  border: 2px solid #003dff;
}

.dataViewScroll {
  margin-left: 15px;
  overflow-y: scroll;
  max-height: 145px;
}

.loaderChartView {
  z-index: 10000;
  position: absolute;
  top: 458px;
  left: 800px;
}

.loader {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #003dff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chartfilter {
  width: 15%;
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  margin-top: 14px;
}

.dataViewEventScroll {
  margin-left: 15px;
  overflow-y: scroll;
  max-height: 145px;
  overflow-x: hidden;
  margin-bottom: "10px";
}

.listMain {
  overflow-y: auto;
}
.listMainCardView {
  height: 507px;
  overflow-x: hidden;
  overflow-y: auto;
}

.listMainModbus {
  height: 430px;
  overflow: auto;
}

.gotoModbus {
  color: #e3e3e3;
  text-decoration: none;
}

.gotoModbus:hover {
  color: #e3e3e3;
}

.tableViewColumn {
  width: 25%;
}

.tableViewFirstColumn {
  width: 17%;
}

.noDataFound {
  text-align: center;
  margin-top: 35px;
}

.tableViewHeaderColumn {
  width: 23%;
}

.tableViewFirstHeaderColumn {
  width: 15%;
}

.latestEventLabel {
  font-weight: bold;
  margin-left: 12px;
  margin-top: 15px;
  padding-bottom: 15px;
}

.latestEventHeader {
  font-weight: bold;
  margin-left: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.connected-Modbus {
  color: #00ae11;
  font-weight: bold;
}

.disconnected-Modbus {
  color: #e30b07;
  font-weight: bold;
}

.tableViewModbus {
  width: 100%;
  margin-left: 15px;
}

.toView {
  padding: 6px;
  margin-top: 10px;
}

.dataEventSet {
  width: 22%;
}

.inputBox {
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  width: 25%;
  margin-top: 14px;
}

.inputDateBox {
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  width: 15%;
  margin-top: 14px;
}

.inputProjectBox {
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  width: 100%;
  margin-bottom: 14px;
  font-size: 15px;
}

.optionsValue {
  border: 0px;
  background-color: white;
  margin-left: 42px;
}

.listView {
  list-style-type: none;
  padding-left: 0px;
}

.inputInfoBox {
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  margin-top: 14px;
}

.eventLabel {
  margin-left: 260px;
}

.inputSearchBox {
  border-radius: 6px;
  border: 1px solid gray;
}

.divSide {
  margin-left: 12px;
  margin-top: -25px;
}

/* card keys */
.card-key {
  font: normal normal 300 14px/16px Montserrat;
  letter-spacing: 0.5px;
  font-weight: 600;
  opacity: 1;
  margin-bottom: 2px;
  white-space: nowrap;
}

.card-keyLabel {
  font: normal normal 300 13px/15px Montserrat;
  letter-spacing: 0.5px;
  font-weight: 600;
  opacity: 1;
  margin-bottom: 2px;
  white-space: nowrap;
  word-break: break-all;
}

.card-list-key {
  font: normal normal 300 14px/16px Montserrat;
  letter-spacing: 0px;
  font-weight: 600;
  opacity: 1;
  margin-bottom: 2px;
  white-space: nowrap;
  color: #000;
  border-style: none;
}

.card-list-value {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  /* color: #000000; */
  opacity: 1;
  margin-bottom: 6px;
  padding-bottom: 4px;
  border-style: none;
}

.card-titles {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  white-space: nowrap;
}

/* card values */
.card-value {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 6px;
  padding-bottom: 4px;
  white-space: nowrap;
}

.card-value-info {
  font: normal normal normal 14px/16px Montserrat;
  letter-spacing: 0px;
  /* color: #000000; */
  opacity: 1;
  margin-bottom: 6px;
  /* padding-bottom: 10px; */
  white-space: nowrap;
}

/* Grid View   */
/* .card-grid {
  display: grid;
  padding: 20px 40px 0px 40px;
  margin: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 10px;
} */

.svg-icons-sm {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.svg-line-sm {
  height: 30px;
  width: 20px;
  margin-left: 10px;
}

.connected-Status {
  background: #11cf19;
  padding: 5px 12px 3px 12px;
  align-items: center;
  display: flex;
  justify-content: start;
}

.disconnected-Status {
  background: #ba1313;
  padding: 5px 12px 3px 12px;
  align-items: center;
  display: flex;
  justify-content: start;
}

.connected-device {
  border-top: solid #11cf19 10px;
  border: #11cf19 solid 0.1px;
}

.project-device {
  border-top: solid #003dff 10px;
  border: #003dff solid 0.1px;
}

.baceName {
  margin-left: 20px;
  margin-top: 17px;
  font-weight: 700;
  font-size: 24px;
}

.disconnected-device {
  border-top: solid #ba1313 10px;
  border: #ba1313 solid 0.1px;
}

.connected-device-info {
  border-color: #11cf19;
}

.connected-device-info {
  border-color: #11cf19;
}

.disconnected-device-info {
  background-color: #ba1313;
}

.headRow {
  background-color: #11cf19;
  margin-top: -1px;
}

.headDisconnectedRow {
  background-color: #ba1313;
  margin-top: -1px;
}

.connected-list {
  color: #11cf19;
  font-weight: bold;
}

.imageValue {
  margin-right: 8px;
  margin-left: -4px;
}

.imageWithoutValue {
  margin-right: 8px;
  margin-left: -4px;
  width: 20px;
}
.imageWithoutValueBaseEP {
  margin-right: 8px;
  width: 20px;
}

.imageBluetoothValue {
  margin-right: 8px;
  margin-left: -4px;
  width: 11px;
}

.modbusDevice {
  /* width: 35%; */
  margin-left: 72px;
  font-size: 32px;
}

.renameButton {
  border: 0px;
  width: 100%;
  background-color: white;
}

.memberButton {
  border: 0px;
  width: 100%;
  background-color: #fff;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#container {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  margin-top: 12px;
}

#container .text {
  border: none;
  background: none;
  font-size: 18px;
  font-weight: 400;
}

#container #menu-wrap {
  position: relative;
  height: 25px;
  width: 25px;
}

#container #menu-wrap .dots {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
}

#container #menu-wrap .dots > div,
#container #menu-wrap .dots > div:after,
#container #menu-wrap .dots > div:before {
  height: 6px;
  width: 6px;
  background-color: rgba(49, 49, 49, 0.8);
  border-radius: 50%;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#container #menu-wrap .dots > div {
  position: relative;
}

#container #menu-wrap .dots > div:after {
  content: "";
  position: absolute;
  bottom: calc((25px / 2) - (6px / 2));
  left: 0;
}

#container #menu-wrap .dots > div:before {
  content: "";
  position: absolute;
  top: calc((25px / 2) - (6px / 2));
  left: 0;
}

#container #menu-wrap .menu {
  position: absolute;
  right: -10px;
  top: calc(-12px + 50px);
  width: 0;
  height: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 15px;
  -webkit-box-shadow: 2px 4px 6px rgba(49, 49, 49, 0.2);
  box-shadow: 2px 4px 6px rgba(49, 49, 49, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
}

#container #menu-wrap .menu ul {
  list-style: none;
}

#container #menu-wrap .menu ul li {
  margin: 15px 0;
}

#container #menu-wrap .menu ul li .link {
  text-decoration: none;
  color: rgba(49, 49, 49, 0.85);
  opacity: 0;
  visibility: hidden;
}

#container #menu-wrap .toggler {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  z-index: 2;
}

#container #menu-wrap .toggler:hover + .dots > div,
#container #menu-wrap .toggler:hover + .dots > div:after,
#container #menu-wrap .toggler:hover + .dots > div:before {
  background-color: rgba(49, 49, 49, 0.6);
}

#container #menu-wrap .toggler:checked + .dots > div {
  -webkit-transform: translateX(calc(((25px / 2) - (6px / 2)) * -0.7071067812))
    translateY(calc(((25px / 2) - (6px / 2)) * -0.7071067812));
  -ms-transform: translateX(calc(((25px / 2) - (6px / 2)) * -0.7071067812))
    translateY(calc(((25px / 2) - (6px / 2)) * -0.7071067812));
  transform: translateX(calc(((25px / 2) - (6px / 2)) * -0.7071067812))
    translateY(calc(((25px / 2) - (6px / 2)) * -0.7071067812));
}

#container #menu-wrap .toggler:checked + .dots > div:after {
  -webkit-transform: translateX(calc(((25px / 2) - (6px / 2)) * 0.7071067812))
    translateY(calc((2 * (25px / 2) - (6px / 2)) * 0.7071067812));
  -ms-transform: translateX(calc(((25px / 2) - (6px / 2)) * 0.7071067812))
    translateY(calc((2 * (25px / 2) - (6px / 2)) * 0.7071067812));
  transform: translateX(calc(((25px / 2) - (6px / 2)) * 0.7071067812))
    translateY(calc((2 * (25px / 2) - (6px / 2)) * 0.7071067812));
}

#container #menu-wrap .toggler:checked + .dots > div:before {
  -webkit-transform: translateX(
      calc(2 * (((25px / 2) - (6px / 2)) * 0.7071067812))
    )
    translateY(
      calc(((25px / 2) - (6px / 2)) - (((25px / 2) - (6px / 2)) * 0.7071067812))
    );
  -ms-transform: translateX(calc(2 * (((25px / 2) - (6px / 2)) * 0.7071067812)))
    translateY(
      calc(((25px / 2) - (6px / 2)) - (((25px / 2) - (6px / 2)) * 0.7071067812))
    );
  transform: translateX(calc(2 * (((25px / 2) - (6px / 2)) * 0.7071067812)))
    translateY(
      calc(((25px / 2) - (6px / 2)) - (((25px / 2) - (6px / 2)) * 0.7071067812))
    );
}

#container #menu-wrap .toggler:checked:hover + .dots > div,
#container #menu-wrap .toggler:checked:hover + .dots > div:after,
#container #menu-wrap .toggler:checked:hover + .dots > div:before {
  background-color: rgba(49, 49, 49, 0.6);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#container #menu-wrap .toggler:checked ~ .menu {
  opacity: 1;
  visibility: visible;
  width: 150px;

  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#container #menu-wrap .toggler:checked ~ .menu ul .link {
  opacity: 1;
  visibility: visible;
  -webkit-transition: 0.5s ease 0.3s;
  -o-transition: 0.5s ease 0.3s;
  transition: 0.5s ease 0.3s;
}

#container #menu-wrap .toggler:checked ~ .menu ul .link:hover {
  color: #2980b9;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

#container #menu-wrap .toggler:not(:checked) ~ .menu {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

#container #menu-wrap .toggler:not(:checked) ~ .menu ul .link {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
}

@media (max-width: 600px) {
  #container {
    position: absolute;
    top: 50px;
    width: calc(100% - 40px);
    margin: 0;
  }
}

.modbusTagSize {
  font-weight: 1000;
}

.modbusTag {
  width: 90%;
  margin-left: 50px;
  margin-top: 53px;
}

.cardInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid #11cf19;
}

.cardDisconnectedInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid #ba1313;
}

.headRowView {
  margin: 12px 12px 12px 9px;
}

.headRowViewStatus {
  margin-left: 12px;
  margin-top: 12px;
}

.tableView {
  width: 97%;
  margin: auto;
}

.tableModbusView {
  width: 78%;
}

.registerDate {
  font-weight: 500;
  font-size: 15px;
}

.resetButton {
  width: 11%;
  height: 62%;
  padding: 6px;
  margin-top: 12px;
  margin-left: 15px;
}

.deviceList {
  margin-top: 0;
  margin-bottom: -2rem;
}

.disconnected-list {
  color: #ba1313;
  font-weight: bold;
}

.labelDesign {
  color: #003dff;
}

.buttonView {
  background-color: #003dff;
}

.labelDesign:hover {
  text-decoration: underline;
}

/*  */
/* card values */
.card-value-red {
  font: normal normal bold 16px/18px Montserrat;
  letter-spacing: 0px;
  color: #ba1313;
  opacity: 1;
  margin-bottom: 6px;
}

/* card values */
.card-value-disconnected {
  font: normal normal bold 16px/18px Montserrat;
  letter-spacing: 0px;
  color: #fff;
  opacity: 1;
  margin-bottom: 6px;
}

.submenu-text-bold {
  font: normal normal bold 16px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-right: 4px;
}

.submenu-text {
  font: normal normal normal 16px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-right: 20px;
}

/* device specs keys */
.specs-key {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.link-text {
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* device specs keys */
.specs-value {
  font: normal normal normal 14px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* device specs keys */
.specs-key-low {
  font: normal normal bold 13.5px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* submenu specs keys */
.submenu-specs-key {
  font: normal normal bold 12px/2px Montserrat;
  margin-top: 10px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* subment specs keys */
.submenu-specs-value {
  font: normal normal normal 14px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* device specs keys */
.validation {
  font: normal normal bold 12px Montserrat;
  margin-top: -16px;
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

/* device specs keys */
.red-border {
  border-color: red;
  border-style: solid;
  border-width: 2px;
}

/* device specs keys */
.specs-value-low {
  font: normal normal normal 16px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

/* notes header */
.notes-header {
  font: normal normal bold 12px/35px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0;
}

/* notes text */
.notes-text {
  font: normal normal normal 16px/22px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.status-dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  display: inline-block;
}

.dot-sensor-online {
  background-color: #11cf19;
}

.dot-online {
  background-color: #003dff;
}

.dot-paused {
  background-color: gray;
}

.dot-event {
  background-color: #ec0000;
}

.form-control {
  background: #f5f5f5 0% 0% no-repeat padding-box;
}

.text-area-no-resize {
  resize: none;
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
  margin-left: 34px;
}

.link-unstyled-view,
.link-unstyled-view:link,
.link-unstyled-view:hover {
  color: inherit;
  text-decoration: inherit;
  margin-left: 14px;
}

.no-border {
  border-bottom: none;
}

.form-select:focus {
  border-color: #fff;
  box-shadow: 0 0 0 0.05rem gray;
}

.left-menu-link:hover {
  text-decoration: none;
  color: #fff !important;
}

.selectGateway {
  overflow-x: hidden;
  max-height: 350px;
  overflow-y: scroll;
  width: 90%;
}

.listViewEvent {
  z-index: 1;
  display: block;
  position: absolute;
  top: 233px;
  width: 225px;
  margin-left: 526px;
  word-break: break-all;
}

.pageView {
  margin-top: 15px;
  margin-left: 15px;
}

.settingModal {
  padding: 0px 7px 4px 16px;
  width: 75%;
}

.applyTemplateNew {
  height: 66%;
}

.btn-cancel {
  padding: 13.5px 18.5px;
  color: #000;
  font: normal normal 600 16px/10px Montserrat;
  border: none;
  text-transform: uppercase;
  background: transparent;
}

.templateInfo {
  cursor: pointer;
  margin-right: 4px;
  margin-left: 10px;
  width: 5%;
}

.tooltips {
  position: initial;
  display: inline-block;
}

.tooltipstext {
  visibility: hidden;
  width: 120px;
  background-color: #003dff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  width: 250px;
  /* Position the tooltip */
  position: absolute;
  z-index: 9999;
  margin-left: 50px;
}

.tooltipstextInfo {
  visibility: hidden;
  width: 115px;
  background-color: #003dff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}

.tooltips:hover .tooltipstext {
  visibility: visible;
}

.tooltips:hover .tooltipstextInfo {
  visibility: visible;
}

.tooltipSetting {
  position: relative;
  display: inline-block;
}

.tooltipsSettingText {
  border: #003dff solid 0.1px;
  visibility: hidden;
  background-color: #fff;
  text-align: center;
  padding: 5px 0;
  width: 250px;
  position: absolute;
  z-index: 4;
  font-weight: 400;
}

.wifiText {
  color: black;
  text-align: center;
  font-weight: 1000;
}

.buttonPerform {
  margin-top: 15px;
}

.tooltipSetting:hover .tooltipsSettingText {
  visibility: visible;
}

.tooltipsTemplate {
  font-size: 12px;
}

.templateDiv {
  color: "black";
}

.cancelIcon {
  float: right;
}

.cancelViewIcon {
  margin-right: 6px;
}

.setValue {
  margin-left: 65px;
  border: none;
  text-decoration-line: underline;
  background-color: #fff;
  color: #003dff;
  font-weight: 600;
}

.errorWifi {
  font-size: 2em;
  position: relative;
  color: #ba1313;
  left: 50%;
  transform: translate(-50%, 16%);
}

.successWifi {
  font-size: 2em;
  position: relative;
  color: green;
  left: 50%;
  transform: translate(-50%, 16%);
}

.radioModbusOption {
  margin-left: -6px;
}

.errorModbus {
  font-size: 21px;
  position: relative;
  color: #ba1313;
  transform: translate(-50%, 16%);
}

.previousbutton {
  background-color: white;
  margin-left: 20px;
  margin-top: 25px;
  float: right;
  border-color: blue;
  color: blue;
  font-weight: 500;
  box-shadow: 0 0.5rem 1rem white !important;
}

.registerView {
  height: 290px;
  overflow-y: auto;
  width: 90%;
}

.slaveView {
  height: 320px;
  overflow-y: auto;
  width: 90%;
}

.buttonTag {
  display: -webkit-inline-box;
}

.previousEditbutton {
  background-color: #003dff;
  margin-left: 210px;
  margin-top: 25px;
  float: right;
  width: 12%;
}

.modbusButton {
  float: right;
  margin-top: 25px;
  margin-left: 15px;
}

.projectSelectedName {
  margin-top: 24px;
  color: #000066;
  font-size: 14px;
  font-weight: 700;
}

.projectScroll {
  margin-left: 15px;
  max-height: 505px;
  overflow-y: scroll;
}

.cardViewDetail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 5px;
  padding: 4px;
  color: #000;
}
.cardViewDetailGridView {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 5px;
  padding: 19px;
  color: #000;

  align-items: flex-start;

  flex: 1 0 0;
}

.projectButton {
  margin-top: 25px;
  margin-left: 15px;
}

.refreshButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modbusResultButton {
  float: right;
  margin-top: 25px;
  margin-left: 15px;
  width: 39%;
}

.questionIcon {
  float: left;
  margin-left: 10px;
}

.wifiList {
  height: 320px;
  overflow-y: scroll;
}

.wifiView {
  margin-left: 20px;
}

.wifiFailVie {
  float: right;
  margin-right: 12px;
}

.loaderView {
  text-align: center;
  margin-top: 150px;
}

.confirmCellular,
.cancelCellular {
  font-size: 16px;
}

.wifiNameView {
  margin-left: 25px;
}

.wifiConfirmbutton {
  margin-top: 12px;
}

.wifiPasswordHide {
  display: none;
}

.wifiPasswordShow {
  display: block;
}

.cancelWifi {
  border: none;
  background-color: #dedede;
  float: right;
}

.cancelErrorWifi {
  border: none;
  background-color: #fff;
  float: right;
}

.wifiOption {
  border: none;
  background-color: #dedede;
  text-align: inherit;
}

.questionWifiIcon {
  float: left;
  margin-left: 10px;
  font-size: 15px;
}

.wififailedMessage {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.isDisabledSettings {
  color: lightgray;
  cursor: not-allowed;

  opacity: 0.5;
  text-decoration: none;
}

.isDisabledSettings:hover {
  color: lightgray;
}

.isDisabledMeasurement {
  color: lightgray;
  cursor: not-allowed;
  margin-left: 20px;
  opacity: 0.5;
  text-decoration: none;
}

.isDisabledMeasurement:hover {
  color: lightgray;
}
.loadSetting {
  cursor: default;
  opacity: 0.5;
}

.wifiMessage {
  text-align: center;
  font-size: 14px;
}

.MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 60%;
}

.submitButton {
  background-color: #2c42b8;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 5px;
}

.cancelTemplate {
  color: gray;
}

.sucessIcon {
  margin-left: 10px;
  width: 100%;
  font-size: 45px;
}

.infoTemplate {
  text-align: center;
  font-weight: 800;
  font-size: 16px;
  margin-top: 26px;
}

.submitDisableButton {
  background-color: gray;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 5px;
}

.applyTemplateArea {
  height: 60%;
}

.templateLabel {
  margin-left: 10px;
}

.applyTemplate {
  margin-left: 9px;
  border: none;
  background-color: #fff;
  color: #003dff;
}

.applyIcon {
  margin-top: 6px;
  font-weight: bold;
}

.settingTemplate {
  font-weight: 600;
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
}

.settingSpace {
  width: 45px;
}

.settingsIcon {
  font-size: 62px;
  color: #003dff;
  text-align: center;
}

.settingsHeaderIcon {
  font-size: 40px;
  position: relative;
  left: 50%;
  color: forestgreen;
  margin-bottom: 15px;
}

.settingsHeaderTextIcon {
  position: relative;
}

.settingConnection {
  text-align: center;
  margin-top: 25px;
}

.settingsConnectionsArea {
  height: 11rem;
  border: #003dff solid 0.1px;
  margin-left: 100px;
  width: 23rem;
}

.settingsSampleArea {
  width: 21rem;
  height: 5rem;
  border: #003dff solid 0.1px;
  margin-left: 45px;
}

.activeSetting {
  color: #11cf19;
}

.inactiveSetting {
  color: #ba1313;
}

.saveModbusTemplate {
  float: right;
  padding: 4px;
  color: #003dff;
  font-weight: 500;
}

.routeWay {
  font-size: 16px;
  font-weight: 400;
}

.settingsMapView {
  margin-top: 0.5px;
}

.settingsMapViewData {
  margin-top: 3px;
  margin-left: 40px;
}

.settingsView {
  display: flex;
}

.settingsViewRate {
  display: flex;
  /* margin-left: 65px; */
}

.inputCellularValue {
  width: 32%;
  margin-left: 40px;
}

.settingSampleGrid {
  /* margin-left: 35px; */
  margin-top: 8px;
  font-size: 12px;
  display: flex;
}

.intervalOption {
  width: 74px;
  height: 27px;
  margin-left: 3px;
}

.intervalWifiOption {
  width: 74px;
  height: 27px;
  margin-left: 6px;
}

.configView {
  margin-top: 2px;
  margin-bottom: 1px;
  margin-left: 7px;
}

.configViewSet {
  margin-top: 2px;
  margin-bottom: 1px;
  margin-left: 55px;
}

.setSecond {
  margin-left: 5px;
  margin-top: 2px;
  font-size: 12px;
}

.templateBlock {
  min-width: 400px;
  max-width: 400px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  margin-top: 20px;
}
.templateBlockScroll {
  overflow-y: scroll;
  margin-top: 20px;
  overflow-x: hidden;
  height: 400px;
}
.templateSecondLayerApplyTemplate {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.settingsGrid {
  font-size: 12px;
}

.settingsLocationGrid {
  font-size: 12px;
  margin-right: 11px;
}

.settingsFirstInfo {
  margin-left: 25px;
  margin-top: 15px;
}

.settingsFirstConnectionInfo {
  display: flex;
  margin: 8px;
}

.settingsSecondInfo {
  margin-left: 33px;
  margin-top: 15px;
}

.settingsThirdInfo {
  margin-left: 37px;
  margin-top: 15px;
}

.settingsConsentArea {
  width: 18rem;
  height: 5rem;
  border: #003dff solid 0.1px;
  margin-left: 45px;
}

.settingsViewArea {
  margin-top: 12px;
}

.settingsViewAreaGateway {
  margin-top: 12px;
  margin-left: 15px;
}

.idDevicetype {
  font-size: 13px;
  float: left;
}

.viewModbus {
  color: #2c42b8;
  display: inline-block;

  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.hideModbus {
  color: #000000;
  display: inline-block;

  text-align: center;
  padding: 14px;
  text-decoration: none;
}

.statusView {
  font-weight: 700;
  font-size: 16px;
}

.modbusGrid {
  margin-left: 32px;
  margin-right: 13px;
}

.arrowModbus {
  font-size: 25px;
  margin-top: 16px;
  font-weight: 500;
  margin-left: 20px;
}

.tabModbusLayout {
  overflow: scroll;
  white-space: nowrap;
  width: 95%;
  flex-flow: nowrap;
}

:root {
  --code-color: darkred;
  --code-bg-color: #aaaaaa;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}

pre {
  color: var(--code-color);
  font-size: var(--code-font-size);
  line-height: var(--code-line-height);
  background-color: var(--code-bg-color);
}

.code-block {
  max-height: 280px;
  overflow: auto;
  padding: 8px 7px 5px 0px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}

.code-block-view {
  max-height: 240px;
  overflow: auto;
  padding: 8px 7px 5px 0px;
  margin: 0px 0px 0px 0px;
  border-radius: 7px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

tbody ::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

tbody ::-webkit-scrollbar {
  /*Your styles here*/
  width: 12px;
  height: 12px;
}

tbody ::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
  /*Your styles here*/
}

.card-register {
  padding: 4px;
  display: flex;
}

.commandSettings {
  height: 6rem;
  border: #003dff solid 0.1px;

  margin-top: 55px;
  padding: 0px 7px 4px 16px;
}

.settingsViewAreaButton {
  margin-left: 140px;
  width: 200px;
}

.settingsViewReboot {
  margin-left: 140px;
  width: 120px;
}

.settingsViewAreaCheck {
  width: 200px;
}

.buttonSettingsCommand {
  text-align: center;
  border-radius: 8px;
  border: 2px solid var(--Sea, #2c42b8);
  background: var(--Light, #fff);
  width: 175px;
  color: var(--Sea, #2c42b8);
}

.buttonSettings {
  text-align: center;
  border-radius: 8px;
  border: 2px solid var(--Sea, #2c42b8);
  background: var(--Light, #fff);
  color: var(--Sea, #2c42b8);
}

.gatewayeCommand {
  padding: 13px 0px 4px 2px;
  margin-bottom: 6px;
  color: var(--Body, #313131);
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.listViewData {
  overflow: visible !important;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
}

.boxList {
  padding-left: 0px;
}

.searchBar {
  display: flex;
  margin-top: 13px;
  margin-left: 33px;
}

.searchIcon {
  margin-left: 8px;
  margin-top: 18px;
}

.list-group-item {
  padding: 5px 5px;
}

.list-group .list-group-item {
  float: none;
  color: black;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 12px;
}

.list-group .list-group-item:hover {
  background-color: #ddd;
  color: black;
}

.formInputSearch {
  border-radius: 6px;
  border: 1px solid gray;
  height: 38px;
  width: 95%;
}
.formInputSearchDeviceId {
  width: 95%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGVSURBVHgBpZTtccIwDIZlhwHYoOkEZIOWCWADMgIDcCTh+N92g7ABG9QbNEzQdIMMwEffFxSOA4eEQ3dK5K/HkizbiEfSNO0bY0YwQ+0qgiAoZrPZn3QQcwUL8fuAjhvmu16vF7fBz9DFYjHY7/cOZh9aQXPoRocHulF4XGRMmiRJdheqHv4o8BOaoa+6npxl2eRwOHCc6ZkC/HUP+ksv2jy4ikiQisiXCsvdNay8DUiZz+cbbH70drvdTn1zLMKJ1V5JR8Eahs30xF4oNKKBFDjpKJrvgt4ul8sXH5SHU8rjUvKDFLz6oJWCH5V6TeWDNobRInXaihsoTtLR2O12sXSUi4pZ+8ZtfZL4p6jBtzYgLwrnatNb/JYnyaJnA0W9hheje0D8vmsvmyrmfPcBSy48yLHRGlpaayukJsLYu5zq8nyoGI9xYVaNUAVPFByKX+qomK68CWzEHyZfJOY30q4SiwuAVvVDow54wV5oV2kCB/KEOOc2w+GwlNNbO6bNPitPCr2jl7T1rZWnoRfgMR9utv8B1ivKXgt3edkAAAAASUVORK5CYII=);
  background-position: 13px 10px;
  background-size: 18px;
  background-repeat: no-repeat;
  padding: 6.5px 37px;
  align-items: flex-start;
  gap: 8px;
}
.formInputSearchDeviceView {
  width: 95%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGVSURBVHgBpZTtccIwDIZlhwHYoOkEZIOWCWADMgIDcCTh+N92g7ABG9QbNEzQdIMMwEffFxSOA4eEQ3dK5K/HkizbiEfSNO0bY0YwQ+0qgiAoZrPZn3QQcwUL8fuAjhvmu16vF7fBz9DFYjHY7/cOZh9aQXPoRocHulF4XGRMmiRJdheqHv4o8BOaoa+6npxl2eRwOHCc6ZkC/HUP+ksv2jy4ikiQisiXCsvdNay8DUiZz+cbbH70drvdTn1zLMKJ1V5JR8Eahs30xF4oNKKBFDjpKJrvgt4ul8sXH5SHU8rjUvKDFLz6oJWCH5V6TeWDNobRInXaihsoTtLR2O12sXSUi4pZ+8ZtfZL4p6jBtzYgLwrnatNb/JYnyaJnA0W9hheje0D8vmsvmyrmfPcBSy48yLHRGlpaayukJsLYu5zq8nyoGI9xYVaNUAVPFByKX+qomK68CWzEHyZfJOY30q4SiwuAVvVDow54wV5oV2kCB/KEOOc2w+GwlNNbO6bNPitPCr2jl7T1rZWnoRfgMR9utv8B1ivKXgt3edkAAAAASUVORK5CYII=);
  background-position: 13px 10px;
  background-size: 18px;
  background-repeat: no-repeat;
  padding: 6.5px 37px;
  align-items: flex-start;
  gap: 8px;
}

.formInputModbusSearch {
  border-radius: 6px;
  border: 1px solid gray;
  height: 35px;
}

.showMode {
  display: block;
}

.showErrorRegisterMode {
  display: block;
  color: #ec0000;
  margin-top: 19px;
}

.showErrorMsgMode {
  display: block;
  color: #ec0000;
  margin-top: 7px;
}

.deviceList {
  background-color: white;
  border: none;
  padding: 14px;
}

.showMsgMode {
  display: block;
  color: #ec0000;
}

.showMsgModbusMode {
  display: block;
  color: #ec0000;
  margin-top: 7px;
}

.hideMode {
  display: none;
}

.plusModbus {
  font-size: 34px;
  height: 6%;
  /* margin-left: 45px;
  margin-top: 20px; */
}

.plusEditModbus {
  font-size: 34px;
  height: 6%;
  margin-top: 20px;
}

.activeMsg {
  border-color: #ba1313;
}

.belowModbusButton {
  position: relative;
  margin-top: 6%;
}

.belowModbusDeviceButton {
  position: absolute;
  bottom: 71px;
  right: 42px;
  z-index: 10;
}

.belowResultButton {
  position: relative;
  margin-top: 16%;
  display: flex;
}

.registerInput {
  width: 80%;
}

.plusRegister {
  font-size: 20px;
  height: 6%;
  margin-top: 20px;
}

.setViewModbus {
  margin-top: 15px;
  font-weight: 600;
}

.formInputWifi {
  border-radius: 6px;
  border: 1px solid gray;
  height: 35px;
  width: 90%;
}

.columnSize {
  width: 15%;
}

.form-control-search,
.formInput {
  height: 35px;
}

.grid-text {
  display: grid;
  grid-template-columns: repeat(2, 0.8fr);
  grid-column-gap: 5px;
  grid-row-gap: 0px;
  align-items: center;
}

.addList {
  height: 500px;
  /* background-color: gainsboro; */
  overflow-y: scroll;
  border-radius: 6px;
}

.addListNew {
  height: 580px;
  /* background-color: gainsboro; */
  overflow-y: scroll;
  border-radius: 6px;
}

.select-wrapper {
  height: 50px;
  overflow-y: visible;
}

.Select__menu-list {
  max-height: 120px !important;
}

.arrowItem {
  font-size: 22px;
  margin-left: 20px;
  margin-right: 20px;
}

.linkView {
  color: #000;
}

.nav-tabs .nav-link.active {
  border-color: #fff #fff #fff;
  border: 0px none transparent;
  font-weight: 600;
}

.nav-tabs .nav-link {
  border: 0px none transparent;
}

.tabModbus {
  border-bottom: #fff;
  border: none;
  align-items: center;
  margin-left: 17px;
  font-size: 18px;
  margin-bottom: 3px;
  margin-top: -12px;
}

.driveModbus {
  margin-right: 8px;
  font-size: 50px;
  color: #003dff;
  font-size: 4em;
}

.modbusTitle {
  font-weight: 600;
}

.modbusNewDevice {
  color: var(--Body, #313131);
  /* Subtitle&Large body */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modbusNewDeviceTypeView {
  color: var(--Body, #313131);
  /* Subtitle&Large body */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 30px;
}

.mySelect {
  height: 35px;
}

.deleteTitle {
  font-weight: 700;
  margin-top: -3px;
}

.header tr th {
  /* Important */
  background-color: rgb(255, 255, 255);
  position: sticky;
  z-index: 100;
  top: 0;
}

table {
  /* Not required only for visualizing */
  border-collapse: collapse;
  width: 100%;
}

.online {
  color: #11cf19;
  font-weight: 500;
  font-size: 14px;
}

.offline {
  color: #ba1313;
  font-size: 14px;
  font-weight: 500;
}

.applyTemplateBorder {
  display: flex;
  padding: 6px 12px;
  font-weight: 500;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Sea, #2c42b8);
  background: var(--Light, #fff);
}

.topFrame {
  display: flex;
  width: 1416px;
  padding: 1px 0px;
  align-items: center;
  gap: 10px;
}

.waveSecondFrame {
  display: inline-flex;
  padding: 1px 0px;
  align-items: center;
  gap: 10px;
  width: 920px;
}
.connectingSettingFrame {
  display: inline-flex;
  padding: 12px 58px;
  align-items: flex-start;
  gap: 40px;
}
.panel-container {
  width: 95%;
  margin: auto;
}
.main-menu-container-new {
  display: flex;
  padding: 0px 32px;
  align-items: flex-start;
  gap: 10px;
}
.logo-top-frame {
  display: inline-flex;
  padding: 8px 0px 0px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
}
.modbusButtonMovegeteway {
  margin-top: 25px;
  margin-left: 15px;
}
.clickbleTr:hover {
  background: var(--10-sea, #eaedf8);
}
