.bace-link {
  font: normal normal normal 16px/28px Montserrat;
  letter-spacing: 0px;
  color: #000;
  padding: 0 20px 0 16px;
  opacity: 1;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.bace-link:hover {
  font: normal normal 500 16px/28px Montserrat;
  color: #003dff;
  text-decoration: none;
}
