.main-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 99999;
}
.main-popup-edit {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 3.5%) !important;
  z-index: 99999;
}
.main-popup-edit-msg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 3.5%) !important;
  z-index: 99999;
}
.main-popup-Creating {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 47.5%) !important;
  z-index: 99999;
}
.main-popup-delete {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 3.5%) !important;
  z-index: 99999;
}
.main-popup-delete-scuccesfuly {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 3.5%) !important;
  z-index: 99999;
}


.popup-style {
  min-height: 135px;
  min-width: 335px;
  padding: 3px !important;
  text-align: center;
  background-color: #fff;
  /* border: black solid 2px; */
  /* border-radius: 5px; */
}

.popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* min-height: 300px;
  min-width: 400px;
  max-height: 80%;
  max-width: 40%; */
  padding: 1.5rem;
  border: black solid 1px;
  border-radius: 7px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.none {
  display: none;
}

.export-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.template-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.template-popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-height: 100%;
  max-width: 40%;
  background-color: white;
}


.template-popup-wifi-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 50%;
  min-width: 350px;
  max-height: 100%;
  max-width: 40%;
  background-color: #dedede;
}

.export-popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 300px;
  min-width: 400px;
  max-height: 80%;
  max-width: 40%;
  background-color: #dedede;
}

.font-weight-bold {
  font-weight: bold;
}

.popup-input {
  width: 70px;
  height: 35px;
  margin-left: 10px;
  margin-right: 5px;
}

.popup-select {
  width: 88px;
  height: 35px;
}

.borderless td,
.borderless th {
  border: none;
}

.bottomleft {
  position: fixed;
  bottom: 30px;
  left: 280px;
}
.bottomleftNew {
  position: fixed;
  bottom: 30px;
  left: 338px;
}
.fixedButtonNew {
  position: fixed;
 /* bottom: 100px; */
  left: 346px;
  z-index: 999;
}

.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 18px;
  grid-row-gap: 0px;
}
.createTemplate{
color: var(--Body, #313131);
text-align: center;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.48px;
}


.form-control {
    background: #f5f5f5 0% 0% no-repeat padding-box;
}
.form-controlNew {
    
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


  .form-controlSec {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 8px;
border: 2px solid var(--Ocean, #006);
background: var(--Light, #FFF);
}
.createTemplatePopup{
  display: inline-flex;
padding: 40px;
flex-direction: column;
padding-top: 0px;
padding-right: 0px;
border-radius: 8px;
border: 2px solid var(--Ocean, #006);
background: var(--Light, #FFF);
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.selectBorder{
  border-radius: 8px;
border: 2px solid var(--Body, #313131);
background: var(--Light, #FFF);
}
.templateNameBorder{
  border-radius: 8px;
background: var(--10-sea, #EAEDF8);
box-shadow: 0px 2px 2px -1px rgba(0, 0, 102, 0.10) inset;
}
.cancelbutton{
  
    padding: 13.5px 18.5px;
    color: #000;
    font: normal normal 600 16px/10px Montserrat;
    border: none;
    
    background: transparent;

}
.cancelbuttonPopupCreate{
  
  padding: 13.5px 18.5px;
  color: #000;
  
  border: none;
  
  background: transparent;

}
.submitButtonnew {
  background-color: #2c42b8;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  margin-right: 16px;
  border-radius: 5px;
}
.paddingRight{
  padding-right: 40px;
}
.editTemplatePopUp{
  display: inline-flex;
padding: 40px;
flex-direction: column;
padding-top: 0px;
padding-right: 0px;
border-radius: 8px;
border: 2px solid var(--Ocean, #006);
background: var(--Light, #FFF);
}
.xyz{
  display: inline-flex;
padding: 8px 12px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
border: 2px solid var(--Ocean, #006);
background: var(--Ocean, #006);

/* float shadow */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.xyz1{
  display: flex;
justify-content: center;
align-items: center;
gap: 10px;
}
.dropDownDeviceName{
  appearance: none;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
}
.margin-top{
  margin-top: 8px;
}
.create-sucess-popup{
  border-radius: 12px;
border: 2px solid var(--Ocean, #006);
background: var(--Light, #FFF);

/* pop shadow */
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}