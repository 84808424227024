.integrationView {
  width: 390px;
  height: 185px;
  border: 2px;
  padding: 40px;
  gap: 36px;
  border: 2px #000066;
}

.hoverCard:hover {
  background-color: #d6ddf7;
}



.overviewSubmenu {
  width: 95px;
  height: 17px;
  top: 11px;
  left: 322px;
  gap: 8px;
}

.ellipses {
  white-space: nowrap;
  width: 15em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipses:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.overview {
  border-collapse: collapse;
  width: 100%;
}

.overview td,
.overview th {
  padding: 6px;
}

.overview tr:hover {
  background-color: #f8f8f8;
}

.overview th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
}

.createDiv {
  width: 50%;
  margin: 29px;
}

.webhookButton:hover {
  font: normal normal 500 16px/28px Montserrat;
  color: #003dff;
  text-decoration: none;
}

.webhookButton {
  margin-top: 24px;
}

.infoPage {
  width: 25rem;
  height: 26.5rem;
  margin-left: 28px;
  margin-top: 20px;
}

.attemptDiv {
  border-collapse: separate;
  border-spacing: 0 1em;
}

.infoPage .MuiSwitch-colorPrimary.Mui-checked {
  color: #f8f8f8;
}

.webhookDeleteButton:hover {
  font: normal normal 500 16px/28px Montserrat;
  color: red;
  text-decoration: none;
}

.webhookDeleteButton {
  margin-top: 22px;
}

.WebhookNumber {
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
}

.divButton {
  justify-content: center;
}

.webhookFlow {
  overflow-y: scroll;
  max-height: 550px;
  max-height: 545px;
  overflow-x: hidden;
}