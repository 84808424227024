.navbar-project-name {
  font: normal normal 300 12px/18px Montserrat;
  letter-spacing: 1.2px;
  color: #e3e3e3;
  opacity: 1;
  padding-left: 20px;
  margin-bottom: 0;
}
.leftMenucontainer{
  display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 4px;
}
.navbar-project-subname {
  font: normal normal bold 20px/36px Montserrat;
  letter-spacing: 0.98px;
  color: #e3e3e3;
  padding-left: 20px;
  padding-top: 16px;
  opacity: 1;
}

.main-menu {
  width: 264px;
  background: #00002a 0% 0% no-repeat padding-box;
}

.projectButtonSwitch {
  background-color: #2c42b8;
  border: 0px;
  display: flex;
}
.main-menu-container {
  /* position: relative; */
  /*  padding-top: 4.6px; */
  /* height: 95vh; */
  margin-left:10px;
   
}
.iconBlack {
  color: #000000;
}

.iconWhite {
  color: #fff;
}
.top-menu {
  margin-left: 224px;
  padding-top: 18px;
  padding-right: 41px;
  /* background-color: #ededed; */
  /* width: calc(100% - 224px); */
}

.topDivView{
  position:absolute;
  top:0;
  right:0;
}

.buildName {
  display: flex;
  margin-left: 22px;
  font-size: 14px;
  font-weight: 600;
}

.versionName {
  margin-left: 4px;
  margin-right: 4px;
}

.top-menu-container {
  right: 0;
}

.sticky-submenu {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  background-color: #ededed;
}

.icon-grey {
  color: #e3e3e3;
}

.user-dialog {
  padding: 1rem 0 1rem 0;
  position: absolute;
  z-index: 1100;
  top: 70px;
  right: 40px;
  max-width: 80%;
  min-height: 73px;
  background: #fefefe 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 3px #00000026;
  opacity: 1;
}

.user-dialog:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 12px;
  border-color: transparent transparent #fefefe transparent;
  right: 8px;
  /* TOP IS DOUBLE THE HEIGHT OF THE BORDER */
  top: -20px;
}

.user-name {
  text-align: left;
  font: normal normal normal 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 0.87;
  padding-left: 23.5px;
  padding-right: 18px;
  padding-bottom: 7px;
}

.logout-btn {
  padding-left: 23.5px;
  background: none;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
  font: normal normal bold 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.87;
}

.main-menu-logo {
  position: absolute;
  bottom: 0;
  width: 150px;
  /* margin-left: 50px; 
  margin-bottom: 30px; */
}

.left-menu-link {
  font: normal normal normal 14px/20px Montserrat;
  letter-spacing: 0.7px;
  color: #e3e3e3 !important;
  padding: 16px 0 16px 26px;
}

.left-menu-link:hover {
  text-decoration: none;
  color: #000066 !important;
}

.left-menu-link-active {
  text-align: left;
  font: normal normal bold 15px/18px Montserrat;
  letter-spacing: 0.7px;
  color: #000066;
 /*  padding: 16px 0; */
}

.left-menu-link-active:hover {
  color: #000066;
}

.left-menu-link-icon {
  padding-left: 24px;
  margin-right: 21px;
  height: 41px;
  display: inline-block;
  vertical-align: middle;
}

.left-menu-link-icon:hover {
  text-decoration: none;
  color: #000066;
}

.active-left-menu-link-icon {
  height: 38px;
  padding-left: 19px;
  border-left: solid 5px #000066;
  padding-top: 8px;
}

.no-underscore {
  text-decoration: none;
}

@media (min-width: 768px) {
  .user-dialog {
    top: 70px;
    max-width: 80%;
    min-width: 177px;
  }
}

.gatewayColor{
color: var(--Ocean, #006);
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 140%; 
margin-left:35px;
}

.basepannelheading{
color: var(--Ocean, #006);
font-family: Montserrat;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: -0.64px;
}
.subMenuHeading{
  color: var(--Body, #313131);

/* Subtitle&Large body */
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.projectShadow{
  display: flex;
  width: 232px;
  
  padding: 6px 9px;
  
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
border: 2px solid var(--Ocean, #006);
background: var(--Ocean, #006);

/* float shadow */
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
 }
.projectShadowTextColor{
 color: var(--Light, #FFF);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 140%; 
text-align: left;
width: 175px;
}
.inactive-left-menu-link-icon-shadow{
  display: flex;
  width: 232px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  
  border-radius: 8px;
  
  
}
.active-left-menu-link-icon-shadow{
  display: flex;
  width: 232px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  
  border-radius: 8px;
 background: var(--Sea2, #6071CA);
 
}
.menuleftcolor{
color: var(--Light, #FFF);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.64px;
}

.projectIcon{
display: flex;
width: 24px;
height: 24px;
justify-content: center;
align-items: center;
margin-left: auto;
color:white;
align-self: center

}

.top-menu-container-new{
  display: inline-flex;
padding: 28px 0px 29px 0px;
flex-direction: column;
align-items: flex-start;
gap: 28px;
}
.project-container-frame{
  display: inline-flex;
padding: 28px 0px 29px 0px;
flex-direction: column;
align-items: flex-start;
gap: 28px;
}
.project-container-name{
  display: flex;
width: 264px;
padding: 0px 16px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
}

