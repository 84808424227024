.login-container {
  margin-top: 195px;
}

.bg-login {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.login-btn {
  display: inline;
  text-align: center;
  text-decoration: none;
  font: normal normal 600 16px/15px Montserrat;
  letter-spacing: 1.6px;
  color: #ffffff;
  opacity: 1;
  background-color: #003dff;
  border-radius: 4px;
  padding: 7px 18px;
  border-color: #003dff;
}

.login-btn:hover {
  color: #ffffff;
  text-decoration: none;
}

.pass-reset-btn {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 12px/18px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.pass-reset-btn:hover {
  color: #ffffff;
  text-decoration: none;
}

.bace-logo {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 70%;
}

.bg-login-container {
  width: 600px;
  height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 6px 12px #000000a3;
  border-radius: 2px;
  opacity: 1;
}

.loginImgDiv {
  height: 30px;
  margin-left: 32px;
}

.loginImg {
  width: 10%;
  margin-left: 12px;
}

.welcomeBoard {
  font-size: 24px;
  text-align: center;
  line-height: 72px;
  font-weight: 400;
}

.successBoard{
  height: 105px;;
}
.loginView {
  text-align: center;
}

.login-register {
  display: inline;
  text-align: center;
  text-decoration: none;
  font: normal normal 600 16px/15px Montserrat;
  letter-spacing: 1.6px;
  background-color: #ffffff;
  opacity: 1;
  color: #003dff;
  border-radius: 4px;
  border-color: #003dff;
  padding: 7px 18px;
  border-style: solid;
  margin-left: 25px;
}

.link-register {
  text-decoration: auto;
}

.cancel-register {
  border-style: hidden;
}
