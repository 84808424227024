.formInputEmail {
  border-radius: 6px;
  border: 1px solid gray;
  height: 38px;
  width: 85%;
}

.emailBox {
  text-align: center;
}

.emailValidation {
  margin-left: 50px;
}

.successView {
  text-align: center;
}
.failView {
  text-align: center;
  color: red;
}
