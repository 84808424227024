.intro-container {
    max-width: 1200;
    margin: auto;
}

.card-data {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: #000066 solid 2px; */
    border-radius: 3px;
    padding-bottom: 10px;
}

.bg {
    border: #000066 solid 2px;
    border-radius: 10px;
    width: 25%;
}

/* Grid View   */
.card-grid {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    grid-column-gap: 25px;
    max-width: 100%;
    margin-left: 10px;
    cursor: pointer;
    padding: 20px 20px 20px 50px;
}



.p {
    padding-top: 5px;
    padding-right: 5px;
}

.getwayCard {
    border-radius: 8px;
    border: 2px solid var(--Ocean, #006);
    background: var(--Light, #FFF);

    /* pop shadow */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}

.gateWayscardheading {
    color: var(--Body, #313131);
    text-align: center;

    /* Subtitle&Large body */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}