.bace-link {
  font: normal normal normal 16px/28px Montserrat;
  letter-spacing: 0px;
  color: #000;
  padding: 0 20px 0 16px;
  opacity: 1;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.bace-link:hover {
  font: normal normal 500 16px/28px Montserrat;
  color: #003dff;
  text-decoration: none;
}

.select-dropdown-options {
  max-width: 300px; /* Set a max width for the dropdown */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis (...) for long text */
  white-space: nowrap; /* Prevent text from wrapping */
}

.inputBoxDropdown {
  border-radius: 6px;
  border: 1px solid gray;
  width: 55%;
  margin-top: 14px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.inputBoxHourDropdown {
  border-radius: 6px;
  border: 1px solid gray;
  width: 10%;
  margin-top: 14px;
}

.chartfilterdata {
  width: 15%;
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  margin-top: 14px;
}
.unitValue {
  margin-bottom: 0px;
}

.unitValueData {
  margin-bottom: 0px;
  margin-left: 7px;
}
