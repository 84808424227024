.blue-line {
  border-bottom: 3px solid #003dff;
  width: 18px;
  height: 50%;
}

.dotted-line {
  border-bottom: 3px dashed #000000;
  width: 18px;
  height: 50%;
}

.font-normal {
  font-weight: normal
}

.template-details {
  font-size: 14px;
}

.red-alert-dot {
  background-color: #fff;
  border: 3px solid red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.btn-export {
  background: #003dff 0% 0% no-repeat padding-box;
  color: #fff;
  font: normal normal 600 16px/10px Montserrat;
  border: none;
  text-transform: uppercase;
  padding: 13.5px 18.5px;
}

.btn-cancel {
  padding: 13.5px 18.5px;
  color: #000;
  font: normal normal 600 16px/10px Montserrat;
  border: none;
  text-transform: uppercase;
  background: transparent;
}

.btn-border-none {
  border: none;
  outline: none;
  background: none;
}

.inputPadding {
  padding-left: 5px;
  padding-right: 5px;
}


.connectionSetting {
  display: flex;
  width: 300px;
  height: 230px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  border: 2px solid var(--Ocean, #006);
  background: var(--10-sea, #EAEDF8);
}

.consent {
  display: flex;
  height: 230px;
  width: 180px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: 2px solid var(--Ocean, #006);
  background: var(--10-sea, #EAEDF8);
}

.settingViewupdated {
  display: inline-flex;
  padding: 12px;
  align-items: flex-start;
  gap: 41px;
}

.sampleRateSetting {
  width: 240px;
  display: flex;
  height: 230px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border: 2px solid var(--Ocean, #006);
  background: var(--10-sea, #EAEDF8);
}

.gatewayCommand {
  width: 803px;
  display: inline-flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);
  background: var(--Light, #FFF);
}

.cloudTextCss {
  color: var(--Body, #313131);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.cloudtextsetting {
  width: 70px;
}

.btncheckconnection {
  display: inline-flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);
  background: var(--Light, #FFF);
}

.settingsFirstInfonew {
  margin-left: 10px;
  margin-top: 15px;
}

.settingsViewAreaCheck1 {
  width: 151px;
}

.settingsViewRebootnew {
  margin-left: 65px;

}

.settingsViewAreaButtonNew {
  margin-left: 141px;

}

.inputSearch {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGVSURBVHgBpZTtccIwDIZlhwHYoOkEZIOWCWADMgIDcCTh+N92g7ABG9QbNEzQdIMMwEffFxSOA4eEQ3dK5K/HkizbiEfSNO0bY0YwQ+0qgiAoZrPZn3QQcwUL8fuAjhvmu16vF7fBz9DFYjHY7/cOZh9aQXPoRocHulF4XGRMmiRJdheqHv4o8BOaoa+6npxl2eRwOHCc6ZkC/HUP+ksv2jy4ikiQisiXCsvdNay8DUiZz+cbbH70drvdTn1zLMKJ1V5JR8Eahs30xF4oNKKBFDjpKJrvgt4ul8sXH5SHU8rjUvKDFLz6oJWCH5V6TeWDNobRInXaihsoTtLR2O12sXSUi4pZ+8ZtfZL4p6jBtzYgLwrnatNb/JYnyaJnA0W9hheje0D8vmsvmyrmfPcBSy48yLHRGlpaayukJsLYu5zq8nyoGI9xYVaNUAVPFByKX+qomK68CWzEHyZfJOY30q4SiwuAVvVDow54wV5oV2kCB/KEOOc2w+GwlNNbO6bNPitPCr2jl7T1rZWnoRfgMR9utv8B1ivKXgt3edkAAAAASUVORK5CYII=);
  background-position: 13px 10px;
  background-repeat: no-repeat;
  width: 350px;
  padding: 8px 50px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Body, #313131);

}

.tableViewHeaderColumnLastSeen {
  width: 23%;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.switch-label .switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked+.switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 60px;
}

.btninsidetext {
  display: flex;
  padding: 1px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.btninsidetextcheckconnection {
  display: flex;
  padding: 1px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.btninsidetextReboot {
  display: flex;
  padding: 1px 50px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}