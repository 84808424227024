.delaGridTable {
  overflow-y: scroll;
  max-height: 275px;
  border: 1px solid black; /* Add border to the container to ensure it's visible when scrolling */
  padding: 5px; /* Add some padding to avoid content touching the border */
  box-sizing: border-box;
}

.customTable {
  border: 1px solid black; /* Adding border to table cells */
  padding: 8px;
}

.customTable tr {
  position: relative; /* Enable absolute positioning for child elements */
}

.nochartData {
  min-height: 390px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  text-align: center;
  margin-bottom: 31px;
  border: 1px solid black;
}
.checkboxCell {
  position: relative; /* Parent for checkbox positioning */
  padding-left: 30px; /* Space for the checkbox */
}

.customTable tr td .checkboxCell {
  border: none;
}

.checkboxOutside {
  left: -20px; /* Move checkbox outside the table border */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the checkbox */
}

.delaFilterOption {
  width: 180px;
  border-radius: 6px;
  border: 1px solid gray;
  height: 28px;
  margin-top: 14px;
}
