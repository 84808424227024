.settingView {
  display: flex;
  margin-left: 90px;
}

.emptyDiv {
  width: 10%;
}
.projectName {
  font-weight: 600;
}

.editSettings {
  margin-bottom: 10px;
  font-size: 30px;
}

.editMessage {
  margin-left: 150px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
}

.boldEditName {
  margin-left: 15px;
  font-weight: 600;
}

.boldDeleteName {
  margin-left: 6px;
  font-weight: 600;
}
.deleteMessage {
  margin-left: 80px;
  font-weight: 500;
  font-size: 16px;
  display: flex;
}

.passDelete {
  font-size: 2em;
  position: relative;
  color: green;
  left: 50%;
  transform: translate(-50%, 16%);
}

.moving-device {
height:450px;
width:350px;
display: flex;
padding: 40px 20px;
flex-direction: column;
align-items: center;
border-radius: 8px;
border: 2px solid var(--Ocean, #006);
background: var(--Light, #FFF);
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.moving-header {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 18px;
}
.infoProjectIcon {
  margin-left: 15px;
  margin-top: 4px;
}
.infoProjectReferIcon {
  margin-left: 15px;
  margin-top: 4px;
  font-size: 3em;
  color: #003dff;
}
.viewIconProject {
  display: flex;
  margin-left: 300px;
  padding: 21px;
}
.headerMember {
  font-size: 16px;
  margin-top: 42px;
  font-weight: 600;
}

.addmember {
  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);
  background: var(--Light, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 12px;
  gap: 8px;
  display: flex;
  color: #006;
}

.disableMember {
  border-radius: 8px;
  border: 2px solid gray;
  background: #e8e8e8;
  box-shadow: 0px 1px 2px 0px gray;
  padding: 8px 12px;
  gap: 8px;
  color: gray;
  margin-left: 15px;
}
.bottomDisableleft {
  position: fixed;
  bottom: 30px;
  left: 430px;
  margin-bottom: 8px;
}

.disableAddMember {
  border-radius: 8px;
  border: 2px solid gray;
  background: #e8e8e8;
  box-shadow: 0px 1px 2px 0px gray;
  padding: 8px 12px;
  gap: 8px;
  color: gray;
  display: flex;
}
.memberColor {
  margin-bottom: 0px;
  margin-top: -3px;
  font-weight: 500;
}

.dropdownViewTag {
  border-radius: 8px;
  border: 2px solid var(--Body, #313131);
  background: var(--Light, #fff);
  width: 95%;
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

.memberinPutBox {
  display: flex;
  width: 327px;
  height: 42px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--10-sea, #eaedf8);
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 102, 0.1) inset;
  border: 0px;
  font-size: 15px;
}

.dialogAdd .MuiDialog-container .MuiDialog-paperWidthSm {
  max-width: 450px;
}
.MuiDialog-container .MuiDialog-paperWidthSm {
  border-radius: 12px;
  border: 2px solid var(--Ocean, #006);
  background: var(--Light, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.15);
}
.memberAccess {
  display: flex;
  width: 327px;
  height: 42px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--10-sea, #eaedf8);
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 102, 0.1) inset;
  font-size: 14px;
  border: 0px;
}

.Addmember {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.addmemberView {
  margin-bottom: 0px;
  font-size: 15px;
}
.addmemberViewText {
  font-size: 12px;
}

.addmemberSuccess {
  margin-bottom: 0px;
  font-weight: bold;
}
.emailCheck {
  font-size: 14px;
  margin-bottom: 0px;
}

.addMemberStatusIcon {
  font-size: 40px;
  position: relative;
  left: 44%;
  color: forestgreen;
  margin-bottom: 15px;
  display: flex;
}

.addMemberFailedStatusIcon {
  font-size: 40px;
  position: relative;
  left: 44%;
  color: #ba1313;
  margin-bottom: 15px;
  display: flex;
}

.disabledOption {
  background-color: #e8e8e8;
  border: 0px;
}

.notRemoveOption {
  color: #ba1313;
  font-size: 20px;
}
.lineSpace {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 12px;
}
.projectLabel {
  margin-bottom: 0px;
  margin-left: 12px;
  width: 65%;
}

.card-wrap-div {
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  grid-column-gap: 12px;
  max-width: 100%;
  cursor: pointer;
}
.failedDelete {
  font-size: 14px;
  width: 80%;
  text-align: center;
  margin-left: 50px;
}

.DeleteProjectMessage {
  margin-left: 165px;
  font-weight: 700;
  font-size: 22px;
  display: flex;
}

.buttonAction {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-bottom: 45px;
  margin-left: 120px;
  display: flex;
}

.moveButton {
  border: 2px solid black;
  border-radius: 8px;
  padding: 5px;
  margin-left: 15px;
}
.dialogAdd {
  border: 2px solid red;
}

.deleteButton {
  border: 2px solid red;
  border-radius: 8px;
  padding: 5px;
  color: red;
}

.buttonViewIcon {
  margin-right: 10px;
  margin-top: 3px;
}
/* Style the select box */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 3px;
  font-size: 16px;
  border: 1px solid #ccc;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}


/* Remove default arrow in Firefox */
select::-ms-expand {
  display: none;
}

/* Style the container holding the select box */
.select-container {
  position: relative;
  border-radius: 8px;
  border: 2px solid var(--Body, #313131);
  
  width: 95%;
  display: block;
  width: 100%;
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}
.removeButtonText{
color: var(--Body, #313131);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-left: 5px;
position: absolute;

}
button span {
  margin-bottom: 5px; /* Adjust the margin as needed */
}
.loadmodbustemplete{
  display: inline-flex;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--Ocean, #006);

}
.loadButtoAlign{
  display: flex;
height: 44px;
justify-content: center;
align-items: flex-start;
gap: 593px;
align-self: stretch;
}
