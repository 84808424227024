.card-list-key {
  font: normal normal 300 16px Montserrat;
  letter-spacing: 0px;
  font-weight: 600;
  opacity: 1;
  margin-bottom: 2px;
  white-space: nowrap;
  color: #000;
  border-style: none;
}

.card-list-value {
  font: normal normal normal 15px Montserrat;
  letter-spacing: 0px;
  /* color: #000000; */
  opacity: 1;
  margin-bottom: 6px;
  padding-bottom: 4px;
  border-style: none;
}

.notificationName {
  font-weight: bold;
  margin-bottom: 0px;
}

.notificationNameMessage {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 0px;
}

.formInputView {
  width: 95%;
  background-position: 13px 10px;
  background-size: 18px;
  background-repeat: no-repeat;
  padding: 6.5px 37px;
  align-items: flex-start;
  gap: 8px;
}
.acknowledgeNotionfication {
  float: inline-end;
  color: red;
  font-weight: 700;
  font-size: 18px;
  margin-right: 22px;
}
