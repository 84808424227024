.modbusDropDownView {
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--Body, #313131);
  background: var(--Light, #fff);
}

.modbusDeviceTypeList {
  width: 100%;
  padding: 37px;
  max-height: 450px;
  min-height: 340px;
  overflow-y: scroll;
}

.buttonViewRange {
  color: #2c42b8;
}

.deviceFunction {
  margin-left: 55px;
}

.modbusInputView {
  display: flex;
  height: 38px;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  background: var(--10-sea, #eaedf8);
  box-shadow: 0px 2px 2px -1px rgba(0, 0, 102, 0.1) inset;
}

.addDevice {
  color: var(--Body, #313131);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-left: 15px;
}

.plusDevice {
  display: flex;
  margin-top: 20px;
}

.buttonDevice:hover {
  color: #003dff;
}
.buttonGenericDevice:hover {
  background-color: var(--10-sea, #eaedf8);
}

.gapAllModbusDevice {
  display: block;
  align-items: flex-start;
  gap: 101px;
  margin-bottom: 30px;
  width: 70%;
}

.deviceRow {
  display: flex;
  width: 256px;
  align-items: flex-start;
}
.activeDeviceSetting {
  background: var(--Sea-4, #cad0ed);
  border: none;
  padding: 0px;
  text-align: left;
}
.deviceActiveView {
  display: flex;
  width: 171px;
  padding: 12px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}

.registerDeviceTypeView {
  height: 290px;
  overflow-y: auto;
  width: 90%;
  margin-left: 50px;
}

.deviceTypeList {
  background-color: white;
  border: none;
  padding: 0px;
  display: flex;
}

.slaveDivView {
  height: 275px;
  overflow-y: auto;
  width: 90%;
}

.deviceView {
  display: flex;
  width: 171px;
  padding: 6px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  text-align: left;
}

.slaveIdView {
  width: 85px;
  padding: 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  text-align: center;
}

.ReturnToGenericDevice {
  color: var(--Sea, #2c42b8);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.64px;
  margin-left: 12px;
}

.genericbutton {
  display: inline-flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid var(--Sea, #2c42b8);
  background: var(--Light, #fff);
  margin-top: 12px;
}
.tooltipstextSelectGateway {
  visibility: hidden;
  width: 120px;
  background-color: var(--Body, #313131);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  width: 500px;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
  left: 10%;
  top: 70%;
}

.belowModbusDeviceButton {
  position: absolute;
  bottom: 71px;
  right: 42px;
  z-index: 10;
}

.nextButton{
  float: inline-end;
  display: flex;
}

.tooltipstextInfoSelectGateway {
  visibility: hidden;
  width: 115px;
  background-color: #003dff;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 2;
}

.tooltipSettingSelectGateway:hover .tooltipstextSelectGateway {
  visibility: visible;
}

.tooltipSettingSelectGateway:hover .tooltipstextInfoSelectGateway {
  visibility: visible;
}
.tooltipSettingSelectGateway:hover .waringMsg {
  visibility: visible;
  color: red;
}
.tooltipSettingSelectGateway {
  position: relative;
}
.tooltipsSettingTextSelectGateway {
  border: #003dff solid 0.1px;
  visibility: hidden;
  background-color: #fff;
  text-align: center;
  padding: 5px 0;
  width: 250px;
  position: absolute;
  z-index: 4;
  font-weight: 400;
}
.tooltipSettingSelectGateway:hover .tooltipsSettingTextSelectGateway {
  visibility: visible;
}

.tooltipsTemplateSelectGateway {
  font-size: 12px;
}
